import * as React from 'react';
import moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {StyledTableCell, StyledTableRow} from 'components/Tables/Styles/TableAndCellStyles'
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Box from '@mui/material/Box';
import { useTheme } from "@mui/material";


export default function BrokersLandlordsTable({brokerLandlords, loadingBrokerLandlords, errorBrokerLandlords, handleMenuOpen}) {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function TablePaginationActions({count, page, rowsPerPage, onPageChange}) {
      const theme = useTheme();
    
      const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
      };
    
      const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
      };
    
      const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
      };
    
      const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
      };
    
      return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
          <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
          >
            {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
          </IconButton>
          <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="previous page"
          >
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          </IconButton>
          <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
          >
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </IconButton>
          <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
          >
            {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
          </IconButton>
        </Box>
      );
    }


  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left"> Landlord First Name</StyledTableCell>
            <StyledTableCell align="left">Landlord Last Name</StyledTableCell>
            <StyledTableCell align="left">Business Name</StyledTableCell>
            <StyledTableCell align="left">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {loadingBrokerLandlords ? (
            <StyledTableRow>

            </StyledTableRow>
        ) : (
            <>
            {(rowsPerPage > 0
            ? brokerLandlords.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : brokerLandlords
          ).map((landlord) => (
                <StyledTableRow key={landlord.id}>
                  <StyledTableCell align="left">{landlord.related_landlord.related_user.first_name}</StyledTableCell>
                  <StyledTableCell align="left">{landlord.related_landlord.related_user.last_name}</StyledTableCell>
                  <StyledTableCell align="left">{landlord.related_landlord?.business_name}</StyledTableCell>
                  
                  <StyledTableCell align="left">
                    <IconButton 
                      color="primary" 
                      size="small" 
                      aria-describedby={landlord.id} 
                      // onClick={props.handleClickPopOver}
                      >
                        <MoreHorizIcon/>
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </>
        )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={3}
              count={brokerLandlords.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

// src/components/Tables/TenantDetailsTable.jsx

import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";

const TenantDetailsTable = ({
  page,
  rowsPerPage,
  handleChangePage,
  paymentData,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="previous payments">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontSize: 18, padding: "16px" }}>Date</TableCell>
            <TableCell align="right" sx={{ fontSize: 18, padding: "16px" }}>
              Amount
            </TableCell>
            <TableCell align="right" sx={{ fontSize: 18, padding: "16px" }}>
              Payment Method
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paymentData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((payment) => (
              <TableRow key={payment.id}>
                <TableCell sx={{ fontSize: 16, padding: "16px" }}>
                  {payment.date}
                </TableCell>
                <TableCell align="right" sx={{ fontSize: 16, padding: "16px" }}>
                  ${payment.amount}
                </TableCell>
                <TableCell align="right" sx={{ fontSize: 16, padding: "16px" }}>
                  {payment.method}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={paymentData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
      />
    </TableContainer>
  );
};

export default TenantDetailsTable;

import React from "react";
import { Stack, Select, MenuItem as SelectMenuItem, FormControl, InputLabel, TextField } from "@mui/material";

export function ManagerDetailsForm({ newProperty, handleChange, unassignedProperties, isLoadingProperties, startDate, handleDateChange }) {
  return (
    <Stack spacing={2}>
      {isLoadingProperties ? (
        <p>Loading Properties...</p>
      ) : (
        <FormControl fullWidth>
          <InputLabel id="related-property-label">Select Property</InputLabel>
          <Select
            labelId="related-property-label"
            id="related-property"
            name="related_property"
            value={newProperty}
            onChange={handleChange}
            label="Select Property"
          >
            {unassignedProperties.map((property) => {
            
              const landlord = ` ${property.rental_name}- ${property.related_landlord.related_user.first_name} ${property.related_landlord.related_user.last_name}` 
              
              return (
                <SelectMenuItem key={property.id} value={property.id}>
                  {landlord}
                </SelectMenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
      <TextField
        id="date-started"
        label="Date Started"
        type="date"
        value={startDate}
        onChange={handleDateChange}
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
      />
    </Stack>
  );
}

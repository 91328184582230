import React from 'react';
import { DialogContent, TextField, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { useRentals } from "services/hooks/rentals";

const ExpenseForm = ({ formData, handleFormChange, handleRentalChange }) => {
    const { rentals, isLoadingRentals, error } = useRentals();

    if (isLoadingRentals) {
        return <div>Loading rentals...</div>;
    }

    if (error) {
        return <div>Error loading rentals</div>;
    }

    return (
        <DialogContent>
            <FormControl fullWidth variant="outlined" margin="dense">
                <InputLabel>Related Landlord Rental</InputLabel>
                <Select
                    name="related_landlord_rental"
                    value={formData.related_landlord_rental }
                    onChange={handleRentalChange}
                    label="Related Landlord Rental"
                >
                    {rentals.map((rental) => (
                        <MenuItem key={rental.id} value={rental.id}>
                            {rental.rental_name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {/* <TextField
                autoFocus
                margin="dense"
                label="Related Ticket"
                type="text"
                fullWidth
                variant="outlined"
                name="related_ticket"
                value={formData.related_ticket || ""}
                onChange={handleFormChange}
            /> */}
            <TextField
                margin="dense"
                label="Amount"
                type="number"
                fullWidth
                variant="outlined"
                name="amount"
                value={formData.amount }
                onChange={handleFormChange}
            />
            <TextField
                margin="dense"
                label="Expense Category"
                type="text"
                fullWidth
                variant="outlined"
                name="expense_category"
                value={formData.expense_category}
                onChange={handleFormChange}
            />
            <TextField
                margin="dense"
                label="Description"
                type="text"
                fullWidth
                variant="outlined"
                name="description"
                value={formData.description }
                onChange={handleFormChange}
            />
        </DialogContent>
    );
};

export default ExpenseForm;

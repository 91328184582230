import React from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Box,
  MenuItem,
  TableFooter,
  Menu,
  TablePagination,
  useTheme
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import MessageIcon from "@mui/icons-material/Message";
import DeleteIcon from "@mui/icons-material/Delete";
import PaymentsIcon from "@mui/icons-material/Payments";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { setBroker } from "state"; 
import { useSelector, useDispatch } from "react-redux";

const BrokersTable = ({brokers,isLoadingBrokers,error,handleMenuClick,handleMenuClose,anchorEl,handleMenuAction}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const theme = useTheme();
  const navigate = useNavigate(); // Initialize useNavigate
  const dispatch = useDispatch()

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function TablePaginationActions({ count, page, rowsPerPage, onPageChange }) {
    const theme = useTheme();

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
 
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              sx={{

                background: "linear-gradient(45deg, #FFC0CB 30%, #FFD700 90%)",
                "& th": {
                  color: "#333333",
                  fontWeight: "bold",
                  fontSize: "16px",
                  backgroundColor: "#FEF0DE",
                },
              }}
            >
              <TableCell sx={{ fontSize: "18px" }}>Name</TableCell>
              <TableCell sx={{ fontSize: "18px" }}>Phone</TableCell>
              <TableCell sx={{ fontSize: "18px" }}>Email</TableCell>
              <TableCell sx={{ fontSize: "18px" }}>Location</TableCell>
              <TableCell sx={{ fontSize: "18px" }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoadingBrokers ? (
              <TableRow>
                <TableCell colSpan={5}>Loading...</TableCell>
              </TableRow>
            ) : error ? (
              <TableRow>
                <TableCell colSpan={5}>Error fetching data</TableCell>
              </TableRow>
            ) : (
              brokers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((broker) => (

               
                <TableRow
                  key={broker.id}
                  sx={{
                    "&:nth-of-type(even)": { backgroundColor: "#f9f9f9" },
                  }}
                >

                  <TableCell sx={{ fontSize: "16px" }}>
                    {broker.broker_name}
                  </TableCell>
                  <TableCell sx={{ fontSize: "16px" }}>
                    {broker.broker_contact}
                  </TableCell>
                  <TableCell sx={{ fontSize: "16px" }}>
                    {broker.broker_email}
                  </TableCell>
                  <TableCell sx={{ fontSize: "16px" }}>
                    {broker.broker_name}
                  </TableCell>
                  <TableCell sx={{ fontSize: "16px" }}>
                    {broker.broker_contact}
                  </TableCell>
                  <TableCell sx={{ fontSize: "16px" }}>
                    {broker.broker_email}
                  </TableCell>
                  <TableCell sx={{ fontSize: "16px" }}>
                    {broker.broker_location}
                  </TableCell>
                  <TableCell>
                    <Button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(e) => handleMenuClick(e, broker)}
                    >
                      <MoreVertIcon />
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={() => handleMenuClose(null)}
                    >
                      <MenuItem onClick={() => handleMenuAction("View")}>
                        <VisibilityIcon color="action" sx={{ mr: 1 }} />
                        View
                      </MenuItem>
                      <MenuItem onClick={() => handleMenuAction("Edit")}>
                        <EditIcon color="action" sx={{ mr: 1 }} />
                        Edit
                      </MenuItem>
                      <MenuItem onClick={() => handleMenuAction("Send Message")}>
                        <MessageIcon color="action" sx={{ mr: 1 }} />
                        Send Message
                      </MenuItem>
                      <MenuItem onClick={() => handleMenuAction("Payments")}>
                        <PaymentsIcon color="action" sx={{ mr: 1 }} />
                        Payments
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleMenuAction("Delete")}
                        sx={{ color: "red" }}
                      >
                        <DeleteIcon sx={{ mr: 1, color: "red" }} />
                        Delete
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={5}
                count={brokers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                   'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default BrokersTable

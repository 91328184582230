import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

export default function FormDialog({open, title, text, content, handleClose, handleSubmit, isSubmittingData}) {

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{fontSize: 20}}>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{fontSize: 15, marginBottom: 2 }}>
            {text}
          </DialogContentText>
          {content}
        </DialogContent>
        <DialogActions>
          {isSubmittingData ? (
            <LoadingButton variant="outlined" loading startIcon={<SaveIcon />} loadingPosition="start" 
            sx={{color: "#188c0b", fontSize: 14}}>Submitting Data ....</LoadingButton>
          ) : (
            <>
              <Button variant="outlined"  onClick={handleClose} sx={{color: "red", fontSize: 14}}>Cancel</Button>
              <Button variant="outlined"  onClick={handleSubmit} sx={{color: "#188c0b", fontSize: 14}}>Submit</Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
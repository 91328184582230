import React from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  IconButton,
  Menu,
  MenuItem,
  TablePagination,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';


const ExpensesTable = ({ data, page, rowsPerPage, onPageChange, handleMenuClick, handleMenuClose, anchorEl }) => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                  Landlord Name
                </Typography>
                </TableCell>
                <TableCell>
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                  Business Name
                </Typography>
                </TableCell>
                <TableCell>
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                  Rental Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                  Description
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px'  }}>
                  Amount
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                  Date
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                  Actions
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((landlordExpense) => (
              <TableRow key={landlordExpense.id}>
                <TableCell>
                  <Typography variant="body1" sx={{ fontSize: '16px' }}>
                    {landlordExpense.related_landlord_rental.related_landlord.related_user.first_name} {landlordExpense.related_landlord_rental.related_landlord.related_user.last_name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" sx={{ fontSize: '16px' }}>
                    {landlordExpense.related_landlord_rental.related_landlord.business_name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" sx={{ fontSize: '16px' }}>
                    {landlordExpense.related_landlord_rental.rental_name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" sx={{ fontSize: '16px' }}>
                    {landlordExpense.description}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" sx={{ fontSize: '16px' }}>
                    {landlordExpense.amount}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" sx={{ fontSize: '16px' }}>
                    {landlordExpense.created_on}
                  </Typography>
                </TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={(event) => handleMenuClick(event, landlordExpense)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => handleMenuClose('edit')}>
                      Edit
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuClose('sendMessage')}>
                      Send Message
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuClose('delete')}>
                      Delete
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={onPageChange}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingRight: '2rem',
        }}
      />
    </>
  );
};

export default ExpensesTable;

import React, {useState} from 'react';
import axios from "axios";
import { baseURL } from 'services/API';
import { Grid, Box, TextField } from "@mui/material";
import TicketsTable from 'components/Tables/TicketsTable';
import Button from '@mui/material/Button';
import BasicSelect from 'components/select';
import FormDialog from 'components/modal';
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import { useGetRentalSchedules, useRentals } from 'services/hooks/rentals';
import TicketPopover from 'components/PopOvers/TicketPopover';
import { formatDate } from 'utilities/date';
import SearchIcon from '@mui/icons-material/Search';
import { FlexRowCenter } from 'components/FlexBox/FlexBox';

export default function Tickets() {
    const [isSidebar, setIsSidebar] = useState(true);
    const [rental, setRental] = useState('')
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const {rentalSchedules, isLoadingRentalSchedules, errorLoadingRentalSchedules} = useGetRentalSchedules()
    const {rentals, isLoadingRentals, error} = useRentals();
    const [searchPhoneNumber, setSearchPhoneNumber] = useState('')
    const [validPhoneNumber, setValidPhoneNumber] = useState(false)

    const openPopper = Boolean(anchorEl);
    const popperId = openPopper ? 'simple-popover' : undefined;

    const handleSelectChange = (event) => {
        setRental(event.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickPopOver = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handleClosePopOver = () => {
        setAnchorEl(null);
      };

    const handleChangeSearch = () => {

    }

    return (
        <Box>
            <Navbar />
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Sidebar isSidebar={isSidebar}/>  
                </Grid>
                <Grid item xs={10}>
                    <Grid container spacing={4}>
                        <Grid item xs={4}>
                            <h1>Active Schedules</h1>
                        </Grid>
                        <Grid item xs={4}>
                            <FlexRowCenter>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="number_search"
                                    label="Phone Number starting with 256"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    value={searchPhoneNumber}
                                    onChange={handleChangeSearch}
                                    sx={{marginTop: 4, marginX: 2}}
                                />
                                {validPhoneNumber ? (
                                    <Button variant="contained" onClick={handleClickOpen} endIcon={<SearchIcon/>} sx={{marginTop: 4}}>
                                        Search
                                    </Button>
                                ) : (
                                    <Button variant="contained" disabled endIcon={<SearchIcon/>} sx={{marginTop: 4}}>
                                        Search
                                    </Button>
                                )}
                                
                            </FlexRowCenter>
                        </Grid>
                        {/* <Grid item xs={4}>
                            <Button variant="contained" onClick={handleClickOpen} endIcon={<AddIcon/>}>
                                Add Ticket
                            </Button>
                        </Grid> */}
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{ marginTop: 3 }}>
                            <TicketsTable rows={rentalSchedules} isLoadingRentalTickets={isLoadingRentalSchedules} handleClickPopOver={handleClickPopOver} id={popperId} formatDate={formatDate}/>
                        </Grid>
                    </Grid>
                    <TicketPopover popperId={popperId} openPopper={openPopper} anchorEl={anchorEl} handleClosePopOver={handleClosePopOver} handleClickOpen={handleClickOpen}/>
                    <FormDialog open={open} handleClose={handleClose}/>
                </Grid>
            </Grid>
        </Box>
    )
}
import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  TableFooter,
  TablePagination,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import MessageIcon from "@mui/icons-material/Message";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { tableCellClasses } from "@mui/material/TableCell";

// Styled components for the table
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FEF0DE",
    color: "#000",
    fontSize: 16,
    fontWeight: 700,
    border: "none",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    border: "none",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f9f9f9",
  },
  "&:hover": {
    backgroundColor: "#f1f1f1",
  },
  "&:last-child td, &:last-child th": {
    border: "none",
  },
}));

// Custom table pagination actions
const TablePaginationActions = (props) => {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{ flexShrink: 0, marginLeft: "auto" }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
};

// LandlordsTable component
const LandlordsTable = ({
  data,
  onActionClick,
  onEditSubmit,
  onSendMessage,
  onDeleteConfirm,
  onViewDetails,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedLandlord, setSelectedLandlord] = React.useState(null);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [messageDialogOpen, setMessageDialogOpen] = React.useState(false);
  const [payDayDialogOpen, setPayDayDialogOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [payDay, setPayDay] = React.useState("");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleMenuClick = (event, landlord) => {
    setAnchorEl(event.currentTarget);
    setSelectedLandlord(landlord);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedLandlord(null);
  };

  const handleEditClick = () => {
    handleMenuClose();
    setEditDialogOpen(true);
  };

  const handleMessageClick = () => {
    handleMenuClose();
    setMessageDialogOpen(true);
  };

  const handleDeleteClick = () => {
    handleMenuClose();
    onActionClick("delete", selectedLandlord);
  };

  const handleViewClick = () => {
    handleMenuClose();
    if (selectedLandlord) {
      navigate(`/landlord-details/${selectedLandlord.id}`);
    }
  };

  const handleMarkPaid = () => {
    handleMenuClose();
    if (selectedLandlord) {
      setPayDayDialogOpen(true);
    }
  };

  const handleMarkPending = () => {
    handleMenuClose();
    if (selectedLandlord) {
      onActionClick("markPending", selectedLandlord);
    }
  };

  const handleEditSubmit = async () => {
    if (selectedLandlord) {
      await onEditSubmit(selectedLandlord);
      setEditDialogOpen(false);
    }
  };

  const handleSendMessage = async () => {
    if (selectedLandlord && message) {
      await onSendMessage(selectedLandlord, message);
      setMessageDialogOpen(false);
      setMessage("");
    }
  };

  const handlePayDaySubmit = async () => {
    if (selectedLandlord && payDay) {
      await onActionClick("markPaid", { ...selectedLandlord, payDay });
      setPayDayDialogOpen(false);
      setPayDay("");
    }
  };

  const handleDialogClose = () => {
    setEditDialogOpen(false);
    setMessageDialogOpen(false);
    setPayDayDialogOpen(false);
    setSelectedLandlord(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Landlord Number</StyledTableCell>
              <StyledTableCell>First Name</StyledTableCell>
              <StyledTableCell>Last Name</StyledTableCell>
              <StyledTableCell>Username</StyledTableCell>
              <StyledTableCell>Email Address</StyledTableCell>
              <StyledTableCell>Business Name</StyledTableCell>
              <StyledTableCell>Pay Day</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((landlord) => (
                <StyledTableRow key={landlord.id}>
                  <StyledTableCell>
                    {landlord.related_user.phone_number}
                  </StyledTableCell>
                  <StyledTableCell>
                    {landlord.related_user.first_name}
                  </StyledTableCell>
                  <StyledTableCell>
                    {landlord.related_user.last_name}
                  </StyledTableCell>
                  <StyledTableCell>
                    {landlord.related_user.username}
                  </StyledTableCell>
                  <StyledTableCell>
                    {landlord.related_user.email}
                  </StyledTableCell>
                  <StyledTableCell>
                    {landlord.business_name}
                  </StyledTableCell>
                  <StyledTableCell>{landlord.payDay}</StyledTableCell>
                  <StyledTableCell>
                    <IconButton
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(e) => handleMenuClick(e, landlord)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(
                        anchorEl && selectedLandlord?.id === landlord.id
                      )}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={handleViewClick}>
                        <VisibilityIcon fontSize="small" />
                        &nbsp; View
                      </MenuItem>
                      <MenuItem onClick={handleEditClick}>
                        <EditIcon fontSize="small" />
                        &nbsp; Edit
                      </MenuItem>
                      <MenuItem onClick={handleMessageClick}>
                        <MessageIcon fontSize="small" />
                        &nbsp; Send Message
                      </MenuItem>
                      <MenuItem onClick={handleDeleteClick}>
                        <DeleteIcon fontSize="small" />
                        &nbsp; Delete
                      </MenuItem>
                      <MenuItem onClick={handleMarkPaid}>
                        <VisibilityIcon fontSize="small" />
                        &nbsp; Mark as Paid
                      </MenuItem>
                      <MenuItem onClick={handleMarkPending}>
                        <VisibilityIcon fontSize="small" />
                        &nbsp; Mark as Pending
                      </MenuItem>
                    </Menu>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                colSpan={8}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      {/* Edit Dialog */}
      <Dialog open={editDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Edit Landlord</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="firstName"
            label="First Name"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={selectedLandlord?.related_user.first_name}
            onChange={(e) =>
              setSelectedLandlord({
                ...selectedLandlord,
                related_user: {
                  ...selectedLandlord.related_user,
                  first_name: e.target.value,
                },
              })
            }
          />
          <TextField
            margin="dense"
            id="lastName"
            label="Last Name"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={selectedLandlord?.related_user.last_name}
            onChange={(e) =>
              setSelectedLandlord({
                ...selectedLandlord,
                related_user: {
                  ...selectedLandlord.related_user,
                  last_name: e.target.value,
                },
              })
            }
          />
          <TextField
            margin="dense"
            id="username"
            label="Username"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={selectedLandlord?.related_user.username}
            onChange={(e) =>
              setSelectedLandlord({
                ...selectedLandlord,
                related_user: {
                  ...selectedLandlord.related_user,
                  username: e.target.value,
                },
              })
            }
          />
          <TextField
            margin="dense"
            id="email"
            label="Email"
            type="email"
            fullWidth
            variant="standard"
            defaultValue={selectedLandlord?.related_user.email}
            onChange={(e) =>
              setSelectedLandlord({
                ...selectedLandlord,
                related_user: {
                  ...selectedLandlord.related_user,
                  email: e.target.value,
                },
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleEditSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>

      {/* Send Message Dialog */}
      <Dialog open={messageDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Send Message</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="message"
            label="Message"
            type="text"
            fullWidth
            variant="standard"
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleSendMessage}>Send</Button>
        </DialogActions>
      </Dialog>

      {/* Mark Paid Dialog */}
      <Dialog open={payDayDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Mark as Paid</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="payDay"
            label="Pay Day"
            type="date"
            fullWidth
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            value={payDay}
            onChange={(e) => setPayDay(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handlePayDaySubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default LandlordsTable;

import * as React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  IconButton,
  Menu,
  MenuItem,
  Box
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import MessageIcon from "@mui/icons-material/Message";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

// Import the styled components
import { StyledTableCell, StyledTableRow } from 'components/Tables/Styles/TableAndCellStyles';

export default function ManagersTable({
  managers = [],
  isLoadingManagers,
  error,
  handleMenuClick,
  handleMenuClose,
  anchorEl,
  currentPage,
  itemsPerPage,
  handlePageChange
}) {
  const [page, setPage] = React.useState(currentPage);
  const [rowsPerPage, setRowsPerPage] = React.useState(itemsPerPage);
  const theme = useTheme();

  React.useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handlePageChange(event, newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ fontSize: "18px" }}>First Name</StyledTableCell>
              <StyledTableCell style={{ fontSize: "18px" }}>Last Name</StyledTableCell>
              <StyledTableCell style={{ fontSize: "18px" }}>Email</StyledTableCell>
              <StyledTableCell style={{ fontSize: "18px" }}>Phone Number</StyledTableCell>
              <StyledTableCell style={{ fontSize: "18px" }}>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {managers.length === 0 ? (
              <StyledTableRow>
                <StyledTableCell colSpan={5}>No data available</StyledTableCell>
              </StyledTableRow>
            ) : (
              managers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((manager, index) => (
                <StyledTableRow key={manager.id} style={{ backgroundColor: index === 0 ? '#f5f5f5' : 'inherit' }}>
                  <StyledTableCell style={{ fontSize: "16px" }}>{manager.related_user.first_name}</StyledTableCell>
                  <StyledTableCell style={{ fontSize: "16px" }}>{manager.related_user.last_name}</StyledTableCell>
                  <StyledTableCell style={{ fontSize: "16px" }}>{manager.related_user.email}</StyledTableCell>
                  <StyledTableCell style={{ fontSize: "16px" }}>{manager.related_user.phone_number}</StyledTableCell>
                  <StyledTableCell>
                    <IconButton onClick={(e) => handleMenuClick(e, manager)}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={() => handleMenuClose()}
                    >
                      <MenuItem onClick={() => handleMenuClose("View")}>
                        <VisibilityIcon />
                        View
                      </MenuItem>
                      <MenuItem onClick={() => handleMenuClose("Edit")}>
                        <EditIcon />
                        Edit
                      </MenuItem>
                      <MenuItem onClick={() => handleMenuClose("Send Message")}>
                        <MessageIcon />
                        Send Message
                      </MenuItem>
                      <MenuItem onClick={() => handleMenuClose("Delete")}>
                        <DeleteIcon />
                        Delete
                      </MenuItem>
                    </Menu>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                count={managers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
}

import * as React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, Grid, InputLabel, Select } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function RentalsEditForm({rentalName, handleChangeRentalName, propertyUse, handleChangePropertyUse, location, handleChangeLocation, locationCounty, handleChangeLocationCounty, security, handleChangeSecurity, hospitalAccess, handleChangeHospitalAccess, roadAccess, handleChangeRoadAccess, parking, handleChangeParking, fibre, handleChangeFibre, elevator, handleChangeElevator, gym, handleChangeGym, pool, handleChangePool  }) {
	return (
		<>
			<Grid container component="form" noValidate autoComplete="off" spacing={2} sx={{marginTop:"1px"}}>
				<Grid item xs={12} sm={6}>
					<TextField size="small" fullWidth value={rentalName} label="Property Name" onChange={handleChangeRentalName} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl fullWidth size="small">
						<InputLabel id="demo-simple-select-label">Property Use Type</InputLabel>
						<Select label="Property Use Type" labelId="rental-type" id="rental-type-id" defaultValue={propertyUse} onChange={handleChangePropertyUse}>
                            <MenuItem value="MIXED USE">MIXED USE</MenuItem>
							<MenuItem value="RESIDENTIAL">RESIDENTIAL</MenuItem>
							<MenuItem value="COMMERCIAL">COMMERCIAL</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl fullWidth size="small">
						<InputLabel id="demo-simple-select-label">Property Location (City)</InputLabel>
						<Select
							label="Property Location (City)"
							labelId="rental-location"
							value={location}
							onChange={handleChangeLocation}
						>
							<MenuItem value="KAMPALA">Kampala</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl fullWidth size="small">
						<InputLabel id="demo-simple-select-label">Property Location (Parish and County)</InputLabel>
						<Select
							label="Property Location (Parish and County)"
							labelId="rental-location-county"
							value={locationCounty}
							onChange={handleChangeLocationCounty}
						>
							<MenuItem value="BUGOLOBI">Bugolobi</MenuItem>
                            <MenuItem value="MBUYA">Mbuya</MenuItem>
                            <MenuItem value="MUTUNGO">Mutungo</MenuItem>
                            <MenuItem value="LUZIRA">Luzira</MenuItem>
                            <MenuItem value="NAKAWA">Nakawa</MenuItem>
                            <MenuItem value="NTINDA">Ntinda</MenuItem>
                            <MenuItem value="KISAASI">Kisaasi</MenuItem>
                            <MenuItem value="NAJEERA">Najeera</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={5} spacing={2}>
					<FormGroup>
						<FormControlLabel control={<Checkbox checked={security} onChange={handleChangeSecurity} />} label="Has 24 Hour Security?" />
                        <FormControlLabel control={<Checkbox checked={hospitalAccess} onChange={handleChangeHospitalAccess}/>} label="Hospital Access in less than 10 minutes" />
						<FormControlLabel control={<Checkbox checked={roadAccess} onChange={handleChangeRoadAccess}/>} label="Main Road Access in less than 5 minutes" />
                        <FormControlLabel control={<Checkbox checked={parking} onChange={handleChangeParking}/>} label="Has Ample Parking Space" />
					</FormGroup>
				</Grid>
				<Grid item xs={12} sm={7} spacing={2}>
					<FormGroup>
                        <FormControlLabel control={<Checkbox checked={fibre} onChange={handleChangeFibre}/>} label="Has Fibre/Wifi?" />
						<FormControlLabel control={<Checkbox checked={elevator} onChange={handleChangeElevator}/>} label="Has Elevator?" />
                        <FormControlLabel control={<Checkbox checked={gym} onChange={handleChangeGym}/>} label="Has Gym?" />
						<FormControlLabel control={<Checkbox checked={pool} onChange={handleChangePool}/>} label="Has Swimming Pool?" />
					</FormGroup>
				</Grid>
			</Grid>
		</>
	);
}
import React from "react";
import { TextField, FormControl, InputLabel, Select, MenuItem, CircularProgress, Typography } from "@mui/material";
import { useBrokerLandlords} from "services/hooks/rentals"; 
import { useManagers } from "services/hooks/rentals";

const AddPaymentForm = ({ newPayment, setNewPayment, broker }) => {
  const { brokerLandlords, loadingBrokerLandlords, errorBrokerLandlords } = useBrokerLandlords(broker.id);
  const { managers, isLoadingManager, error: managersError } = useManagers();

  const handleChange = (e) => {
    setNewPayment({ ...newPayment, [e.target.name]: e.target.value });
  };

  return (
    <>
      <TextField
        label="Date Paid"
        name="date_paid"
        type="date"
        value={newPayment.date_paid}
        onChange={handleChange}
        InputLabelProps={{ shrink: true }}
        fullWidth
        sx={{ mb: 2 }}
      />

      <TextField
        label="Amount"
        name="amount"
        type="number"
        value={newPayment.amount}
        onChange={handleChange}
        fullWidth
        sx={{ mb: 2 }}
      />

      <TextField
        label="Description"
        name="description"
        type="text"
        value={newPayment.description}
        onChange={handleChange}
        fullWidth
        sx={{ mb: 2 }}
      />

      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="broker-label">Broker-Landlord</InputLabel>
        {loadingBrokerLandlords ? (
          <CircularProgress size={24} sx={{ ml: 2 }} />
        ) : errorBrokerLandlords ? (
          <Typography color="error">Error fetching brokers</Typography>
        ) : (
          <Select
            labelId="broker-landlord-label"
            name="broker_landlord"
            value={newPayment.broker_landlord}
            onChange={handleChange}
          >
            {brokerLandlords.length > 0 ? (
              brokerLandlords.map((brokerLandlord) => (
                <MenuItem key={brokerLandlord.id} value={brokerLandlord.id}>
                  {brokerLandlord.related_broker.broker_name} - {brokerLandlord.related_landlord.related_user.first_name} {brokerLandlord.related_landlord.related_user.first_name} 
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">No Broker-Landlords Available</MenuItem>
            )}
          </Select>
        )}
      </FormControl>

      <TextField
        label="Related Month"
        name="related_month"
        type="text"
        value={newPayment.related_month}
        onChange={handleChange}
        fullWidth
        sx={{ mb: 2 }}
      />

      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="paid_by-label">Paid By</InputLabel>
        {isLoadingManager ? (
          <CircularProgress size={24} sx={{ ml: 2 }} />
        ) : managersError ? (
          <Typography color="error">Error fetching managers</Typography>
        ) : (
          <Select
            labelId="paid_by-label"
            name="paid_by"
            value={newPayment.paid_by}
            onChange={handleChange}
          >
            {managers.length > 0 ? (
              managers.map((manager) => (
                <MenuItem key={manager.id} value={manager.id}>
                  {manager.related_user.first_name} 
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">No Managers Available</MenuItem>
            )}
          </Select>
        )}
      </FormControl>
    </>
  );
};

export default AddPaymentForm;

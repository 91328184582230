import * as React from "react";
import { Grid, TextField } from "@mui/material";

export default function ManagerForm({ handleChange, newManager }) {
  return (
    <Grid container spacing={2}>
      {/* Row 1 */}
      <Grid item xs={12} sm={6}>
        <TextField
          autoFocus
          margin="dense"
          name="first_name"
          label="First Name"
          type="text"
          fullWidth
          variant="outlined"
          value={newManager.first_name}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          margin="dense"
          name="last_name"
          label="Last Name"
          type="text"
          fullWidth
          variant="outlined"
          value={newManager.last_name}
          onChange={handleChange}
        />
      </Grid>

      {/* Row 2 */}
      <Grid item xs={12} sm={6}>
        <TextField
          margin="dense"
          name="username"
          label="Username"
          type="text"
          fullWidth
          variant="outlined"
          value={newManager.username}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          margin="dense"
          name="email"
          label="Email"
          type="email"
          fullWidth
          variant="outlined"
          value={newManager.email}
          onChange={handleChange}
        />
      </Grid>

      {/* Row 3 */}
      <Grid item xs={12} sm={6}>
        <TextField
          margin="dense"
          name="phone_number"
          label="Phone Number"
          type="text"
          fullWidth
          variant="outlined"
          value={newManager.phone_number}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          margin="dense"
          name="alternative_contact"
          label="Alternative Contact"
          type="text"
          fullWidth
          variant="outlined"
          value={newManager.alternative_contact}
          onChange={handleChange}
        />
      </Grid>

      {/* Row 4 */}
      <Grid item xs={12}>
        <TextField
          margin="dense"
          name="password"
          label="Password"
          type="password"
          fullWidth
          variant="outlined"
          value={newManager.password}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
}

import React from "react";
import { manager_id } from "state";
import { Box, Typography, useTheme } from "@mui/material";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";

// Import icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import FolderIcon from "@mui/icons-material/Folder";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";
import BusinessIcon from "@mui/icons-material/Business";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import GroupsIcon from "@mui/icons-material/Groups";
import ApartmentIcon from "@mui/icons-material/Apartment";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts"; // Import icon for Properties Managed

const Sidebar = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const universalMedium = theme.palette.universal.medium;

  return (
    <Box
      sx={{
        height: "100vh",
        width: "200px",
        position: "sticky",
        top: 0,
        zIndex: 1,
        "& .pro-sidebar-inner": {
          backgroundColor: "black !important",
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "1px 5px !important", // Further reduced padding
        },
        "& .pro-inner-item:hover": {
          color: "#FEF0DE !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar>
        <Menu iconShape="square">
          <MenuItem
            style={{
              margin: "1px 0", 
              color: universalMedium,
              fontSize: "18px", 
              display: "flex",
              justifyContent: "center",
              padding: "3px 5px", 
            }}
          >
            <Box display="flex" alignItems="center" width="100%">
              <Typography
                variant="h3"
                color={universalMedium}
                style={{ fontSize: "18px" }}
              >
                ADMIN
              </Typography>
            </Box>
          </MenuItem>

          <Box mb="10px"> 
            <MenuItem
              icon={<FolderIcon />}
              style={{
                margin: "1px 0",
                color: universalMedium,
                fontSize: "18px", 
                padding: "3px 5px", 
              }}
              onClick={() => navigate("/tenants")}
            >
              Users
            </MenuItem>
            <MenuItem
              icon={<GroupsIcon />}
              style={{
                margin: "1px 0",
                color: universalMedium,
                fontSize: "18px",
                padding: "3px 5px",
              }}
              onClick={() => navigate("/brokers")}
            >
              Brokers
            </MenuItem>
            <MenuItem
              icon={<ApartmentIcon />}
              style={{
                margin: "1px 0",
                color: universalMedium,
                fontSize: "18px",
                padding: "3px 5px",
              }}
              onClick={() => navigate("/saving-tenants")}
            >
              Tenants
            </MenuItem>
            <MenuItem
              icon={<SettingsIcon />}
              style={{
                margin: "1px 0",
                color: universalMedium,
                fontSize: "18px",
                padding: "3px 5px",
              }}
            >
              Settings
            </MenuItem>
            <MenuItem
              icon={<SupervisorAccountIcon />}
              style={{
                margin: "1px 0",
                color: universalMedium,
                fontSize: "18px",
                padding: "3px 5px",
              }}
              onClick={() => navigate("/manager")}
            >
              Managers
            </MenuItem>
            <MenuItem
              icon={<BusinessIcon />}
              style={{
                margin: "1px 0",
                color: universalMedium,
                fontSize: "18px",
                padding: "3px 5px",
              }}
              onClick={() => navigate("/landlords")}
            >
              Landlords
            </MenuItem>
            <MenuItem
              icon={<ReceiptIcon />}
              style={{
                margin: "1px 0",
                color: universalMedium,
                fontSize: "18px",
                padding: "3px 5px",
              }}
              onClick={() => navigate("/payments")}
            >
              Payments
            </MenuItem>
            <MenuItem
              icon={<DashboardIcon />}
              style={{
                margin: "1px 0",
                color: universalMedium,
                fontSize: "18px",
                padding: "3px 5px",
              }}
              onClick={() => navigate("/home")}
            >
              Dashboard
            </MenuItem>
            <MenuItem
              icon={<HomeWorkIcon />}
              style={{
                margin: "1px 0",
                color: universalMedium,
                fontSize: "18px",
                padding: "3px 5px",
              }}
              onClick={() => navigate("/properties")}
            >
              Properties
            </MenuItem>
            <MenuItem
              icon={<PeopleIcon />}
              style={{
                margin: "1px 0",
                color: universalMedium,
                fontSize: "18px",
                padding: "3px 5px",
              }}
              onClick={() => navigate("/schedules")}
            >
              Active Schedules
            </MenuItem>
            <MenuItem
              icon={<AttachMoneyIcon />}
              style={{
                margin: "1px 0",
                color: universalMedium,
                fontSize: "18px",
                padding: "3px 5px",
              }}
              onClick={() => navigate("/landlord-expenses")}
            >
              Landlord Expenses
            </MenuItem>
            <MenuItem
              icon={<ManageAccountsIcon />}
              style={{
                margin: "1px 0",
                color: universalMedium,
                fontSize: "18px",
                padding: "3px 5px",
              }}
              onClick={() => navigate(`/properties-managed/${manager_id}`)}
            >
              Properties Managed
            </MenuItem>
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;

import * as React from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { baseURL } from "services/API";
import { Grid, Box, Button, Typography, Snackbar, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import ManagersTable from "components/Tables/ManagerTable";
import { setManager } from "state";
import { useManagers } from "services/hooks/rentals"; 
import FormDialog from "components/modal";
import ManagerForm from "components/Forms/ManagerForm";

export default function Managers() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const [open, setOpen] = React.useState(false);
  const [isSubmittingData, setIsSubmittingData] = React.useState(false);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [messageDialogOpen, setMessageDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(0);
  const itemsPerPage = 10;

  const [newManager, setNewManager] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    username: "",
    password: "",
    alternative_contact: "",
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedManager, setSelectedManager] = React.useState(null);

  // Snack bar state
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");

  const {
    managers,
    isLoadingManager,
    error,
    refetch
  } = useManagers(); 

  const handleMenuClick = (event, manager) => {
    setAnchorEl(event.currentTarget);
    setSelectedManager(manager);
  };

  const handleMenuClose = (action) => {
    setAnchorEl(null);
    if (action === "Edit") {
      setEditDialogOpen(true);
    } else if (action === "Send Message") {
      setMessageDialogOpen(true);
    } else if (action === "Delete") {
      setDeleteDialogOpen(true);
    } else if (action === "View") {
      if (selectedManager) {
        dispatch(setManager(selectedManager))
        navigate(`/manager-details/${selectedManager.id}`);
      }
    }
  };

  const handleDialogClose = () => {
    setEditDialogOpen(false);
    setMessageDialogOpen(false);
    setDeleteDialogOpen(false);
    setOpen(false);
    setNewManager({
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      username: "",
      password: "",
      alternative_contact: "",
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewManager((prevManager) => ({ ...prevManager, [name]: value }));
  };

  const handleSubmitManager = async () => {
    setIsSubmittingData(true);
    try {
      const response = await axios.post(`${baseURL}/admin/managers`, newManager);
      const { data } = response;
      setSnackMessage(`Manager ${newManager.first_name} ${newManager.last_name} added successfully ` ) ;
      setOpenSnack(true);
      refetch(); 
    } catch (error) {
      console.error(error);
      setSnackMessage("Failed to add manager");
      setOpenSnack(true);
    } finally {
      setIsSubmittingData(false);
      handleDialogClose();
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSnackClose = () => {
    setOpenSnack(false);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Navbar />
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        <Sidebar />
        <Box sx={{flexGrow: 1, paddingLeft: "260px", padding: "80px"}}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
                <Typography fontWeight="bold" fontSize="25px">
                  Managers
                </Typography>
                <Button variant="contained" onClick={() => setOpen(true)}>
                  Add Manager
                </Button>
              </Box>

              <FormDialog
                open={open}
                title="Add New Manager"
                text="Please fill in the details below to add a new manager."
                content={
                  <ManagerForm
                    handleChange={handleChange}
                    newManager={newManager}
                  />
                }
                handleClose={handleDialogClose}
                handleSubmit={handleSubmitManager}
                isSubmittingData={isSubmittingData}
              />

              <ManagersTable
                managers={managers}
                isLoadingManagers={isLoadingManager} 
                error={error} 
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                handlePageChange={handlePageChange}
                handleMenuClick={handleMenuClick}
                handleMenuClose={handleMenuClose}
                anchorEl={anchorEl}
              />
            </Grid>
          </Grid>
        </Box>

        {/* SnackBar Component */}
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleSnackClose}
        >
          <Alert
            onClose={handleSnackClose}
            severity={snackMessage.includes("Failed") ? "error" : "success"}
            sx={{ width: "100%" }}
          >
            {snackMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
}

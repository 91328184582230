import * as React from "react";
import axios from "axios";
import { baseURL } from "../API";
import { useSelector } from "react-redux";
import { useState, useEffect } from 'react';

// Custom hooks

const useRentals = () => {
	const token = useSelector((state) => state.token);
	const landlordId = useSelector((state) => state.user.landlord_id);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [rentals, setRentals] = React.useState([]);
	const [isLoadingRentals, setLoadingRentals] = React.useState(true);
	const [error, setError] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/rentals?landlord_id=${landlordId}`);
				setRentals(response.data.data);
				setLoadingRentals(false);
			} catch (e) {
				setError(true);
				setLoadingRentals(false);
			}
		};
		fetchData();
	}, [landlordId, token]);

	return { rentals, isLoadingRentals, error };
};

const useRentalUnits = () => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const landlordId = useSelector((state) => state.user.id);
	const [rentalUnits, setRentalUnits] = React.useState("");
	const [isLoadingRentalUnits, setLoadingRentalUnits] = React.useState(true);
	const [error, setError] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/rentals/units?rental_id=${landlordId}`);
				setRentalUnits(response.data.data);
				setLoadingRentalUnits(false);
			} catch (e) {
				setError(true);
				setLoadingRentalUnits(false);
			}
		};
		fetchData();
	}, [landlordId, token]);

	return { rentalUnits, isLoadingRentalUnits, error };
};

const useHandleRentalFormSubmit = async (rentalName, rentalType, rentalLocation) => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const landlordId = useSelector((state) => state.user.id);

	try {
		await axios.post(`${baseURL}/landlords/rentals`, {
			related_landlord: landlordId,
			rental_name: rentalName,
			rental_type: rentalType,
			location: rentalLocation,
		});
		// Handle success
	} catch (err) {
		console.error(err);
		// Handle error
	}
};

const useGetRentalSchedules = () => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [rentalSchedules, setRentalSchedules] = React.useState([]);
	const [isLoadingRentalSchedules, setLoadingRentalSchedules] = React.useState(true);
	const [errorLoadingRentalSchedules, setErrorLoadingRentalSchedules] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/tenants/schedules/admin`);
				setRentalSchedules(response.data.data);
				setLoadingRentalSchedules(false);
			} catch (e) {
				setErrorLoadingRentalSchedules(true);
				setLoadingRentalSchedules(false);
			}
		};
		fetchData();
	}, [token]);

	return { rentalSchedules, isLoadingRentalSchedules, errorLoadingRentalSchedules };
};

const useGetRentalTenants = () => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [rentalTenants, setRentalTenants] = React.useState([]);
	const [isLoadingRentalTenants, setLoadingRentalTenants] = React.useState(true);
	const [errorLoadingRentalTenants, setErrorLoadingRentalTenants] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/accounts/tenants`);
				setRentalTenants(response.data.data);
				setLoadingRentalTenants(false);
			} catch (e) {
				setErrorLoadingRentalTenants(true);
				setLoadingRentalTenants(false);
			}
		};
		fetchData();
	}, [token]);

	return { rentalTenants, isLoadingRentalTenants, errorLoadingRentalTenants };
};

const useGetDashboardData = () => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const landlordId = useSelector((state) => state.user.id);
	const [dashboardData, setDashboardData] = React.useState({});
	const [isLoadingDashboardData, setLoadingDashboardData] = React.useState(true);
	const [errorLoadingDashboardData, setErrorLoadingDashboardData] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/admin/dashboard`);
				setDashboardData(response.data.data);
				setLoadingDashboardData(false);
			} catch (e) {
				setErrorLoadingDashboardData(true);
				setLoadingDashboardData(false);
			}
		};
		fetchData();
	}, [landlordId, token]);

	return { dashboardData, isLoadingDashboardData, errorLoadingDashboardData };
};

const useGetPaymentsData = () => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const landlordId = useSelector((state) => state.user.id);
	const [paymentsData, setPaymentsData] = React.useState([]);
	const [isLoadingPaymentsData, setLoadingPaymentsData] = React.useState(true);
	const [errorLoadingPaymentsData, setErrorLoadingPaymentsData] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/tenants/payments/admin`);
				setPaymentsData(response.data.data);
				setLoadingPaymentsData(false);
			} catch (e) {
				setErrorLoadingPaymentsData(true);
				setLoadingPaymentsData(false);
			}
		};
		fetchData();
	}, [landlordId, token]);

	return { paymentsData, isLoadingPaymentsData, errorLoadingPaymentsData };
};

const useLandlords = () => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [landlords, setLandlords] = React.useState([]);
	const [isLoadingLandlords, setLoadingLandlords] = React.useState(true);
	const [error, setError] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/admin/landlords`);
				setLandlords(response.data.data);
				setLoadingLandlords(false);
			} catch (e) {
				setError(true);
				setLoadingLandlords(false);
			}
		};
		fetchData();
	}, [token]);

	return { landlords, isLoadingLandlords, error };
};

const useBrokers = () => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [brokers, setBrokers] = React.useState([]);
	const [isLoadingBrokers, setLoadingBrokers] = React.useState(true);
	const [error, setError] = React.useState(false);

	const fetchBrokers = async () => {
		try {
			const response = await axios.get(`${baseURL}/landlords/admin/brokers`);
			setBrokers(response.data.data);
			setLoadingBrokers(false);
		} catch (e) {
			console.error("Error fetching brokers:", e);
			setError(true);
			setLoadingBrokers(false);
		}
	};

	React.useEffect(() => {
		fetchBrokers();
	}, [token]);

	return { brokers, isLoadingBrokers, error, refetch: fetchBrokers };
};


const useAttachLandlord = () => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const attachLandlord = async (brokerId, landlordId) => {
		setLoading(true);
		try {
			const response = await axios.post(
				`${baseURL}/landlords/admin/brokers/landlords`,
				{
					related_broker: brokerId,
					related_landlord: landlordId,
				}
			);
			setLoading(false);
			return response.data;
		} catch (error) {
			setLoading(false);
			setError(error);
			throw error;
		}
	};

	return { attachLandlord, loading, error };
};



const useBrokerLandlords = (brokerId) => {
	const [brokerLandlords, setBrokerLandlords] = useState([]);
	const [loadingBrokerLandlords, setLoadingBrokerLandlords] = useState(true);
	const [errorBrokerLandlords, setErrorBrokerLandlords] = useState(null);
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

	useEffect(() => {
		const fetchLandlords = async () => {
			try {
				const response = await axios.get(
					`${baseURL}/landlords/admin/brokers/landlords?broker_id=${brokerId}`,
				);
				setBrokerLandlords(response.data.data);
				setLoadingBrokerLandlords(false)
			} catch (error) {
				setErrorBrokerLandlords(error);
				setLoadingBrokerLandlords(false)
			} finally {
				setLoadingBrokerLandlords(false);
			}
		};

		fetchLandlords();
	}, [brokerId]);

	return { brokerLandlords, loadingBrokerLandlords, errorBrokerLandlords };
};

const useTenants = () => {
	const [tenants, setTenants] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

	useEffect(() => {
		const fetchTenants = async () => {
			try {
				const response = await axios.get(`${baseURL}/accounts/users/tenant_users?user_filter=1`);
				setTenants(response.data.data);
			} catch (err) {
				setError(err);
			} finally {
				setLoading(false);
			}
		};

		fetchTenants();
	}, []);

	return { tenants, loading, error };
};

export const useUsers = () => {
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

	useEffect(() => {
		const fetchUsers = async () => {
			try {
				const response = await axios.get(`${baseURL}/accounts/users/tenant_users?user_filter=0`);

				setUsers(response.data.data);
			} catch (err) {
				setError(err);
			} finally {
				setLoading(false);
			}
		};

		fetchUsers();
	}, []);

	return { users, loading, error };
};
const useManagers = () => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [managers, setManagers] = useState([]);
	const [isLoadingManager, setLoadingManager] = useState(true);
	const [error, setError] = useState(false);

	const fetchManager = async () => {
		try {
			const response = await axios.get(`${baseURL}/admin/managers`);
			setManagers(response.data.data);
			setLoadingManager(false);
		} catch (e) {
			console.error("Error fetching managers:", e);
			setError(true);
			setLoadingManager(false);
		}
	};

	React.useEffect(() => {
		fetchManager();
	}, [token]);

	return { managers, isLoadingManager, error, refetch: fetchManager };
};
const useAttachedProperty = (managerId) => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [attachedProperties, setAttachedProperty] = useState([]);
	const [isLoadingAttachedProperties, setLoadingAttachedProperties] = useState(true);
	const [errorAttachedProperties, setError] = useState(false);

	const fetchAttachedProperty = async () => {
		try {
			const response = await axios.get(`${baseURL}/admin/managers/properties?manager_id=${managerId}`);
			setAttachedProperty(response.data.data);
			setLoadingAttachedProperties(false);
		} catch (e) {
			console.error("Error fetching managers:", e);
			setError(true);
			setLoadingAttachedProperties(false);
		}
	};

	React.useEffect(() => {
		fetchAttachedProperty();
	}, []);

	return { attachedProperties, isLoadingAttachedProperties, errorAttachedProperties, refetch: fetchAttachedProperty };
};
const useUnAssignedProperties = () => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

	const [unAssignedProperties, setUnAssignedProperties] = useState([]);
	const [isLoadingUnAssignedProperties, setLoadingUnAssignedProperties] = useState(true);
	const [errorUnAssignedProperties, setError] = useState(false);

	const fetchUnAssignedProperty = async () => {
		try {
			const response = await axios.get(`${baseURL}/admin/unassigned_properties`);
			setUnAssignedProperties(response.data.data);
			setLoadingUnAssignedProperties(false);
		} catch (e) {
			console.error("Error fetching unassigned properties:", e);
			setError(true);
			setLoadingUnAssignedProperties(false);
		}
	};

	useEffect(() => {
		fetchUnAssignedProperty();
	}, []);

	return { unAssignedProperties, isLoadingUnAssignedProperties, errorUnAssignedProperties, refetch: fetchUnAssignedProperty };
};
const useLandlordExpenses = () => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [landlordExpenses, setLandlordExpenses] = React.useState([]);
	const [isLoadingLandlordExpenses, setLoadingLandlordExpenses] = React.useState(true);
	const [errorLandlordExpenses, setError] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/admin/landlord_expenses`);
				setLandlordExpenses(response.data.data);
				setLoadingLandlordExpenses(false);
			} catch (e) {
				setError(true);
				setLoadingLandlordExpenses(false);
			}
		};
		fetchData();
	}, [token]);

	return { landlordExpenses, isLoadingLandlordExpenses, errorLandlordExpenses };
};

const useBrokerPayments = (brokerId) => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [brokerPayments, setBrokerPayments] = React.useState([]);
	const [isLoadingBrokerPayments, setLoadingBrokerPayments] = React.useState(true);
	const [errorBrokerPayments, setError] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/admin/broker_payments?broker_id=${brokerId}`);
				setBrokerPayments(response.data.data);
				setLoadingBrokerPayments(false);
			} catch (e) {
				setError(true);
				setLoadingBrokerPayments(false);
			}
		};
		fetchData();
	}, []);

	return { brokerPayments, isLoadingBrokerPayments, errorBrokerPayments };
};


// Export hooks
export {
	useRentals,
	useHandleRentalFormSubmit,
	useRentalUnits,
	useGetRentalSchedules,
	useGetRentalTenants,
	useGetDashboardData,
	useGetPaymentsData,
	useLandlords,
	useBrokers,
	useAttachLandlord,
	useBrokerLandlords,
	useTenants,
	useManagers,
	useAttachedProperty,
	useUnAssignedProperties,
	useLandlordExpenses,
	useBrokerPayments
};



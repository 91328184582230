// src/App.js
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import LoginPage from "scenes/loginPage";
import ProfilePage from "scenes/profilePage";
import Properties from "scenes/Properties";
import Tenants from "scenes/Tenants"
import Users from "scenes/Users"
import Tickets from "scenes/Tickets";
import Payments from "scenes/Payments";
import Dashboard from "scenes/Dashboard";
import Landlords from "scenes/Landlords";
import Brokers from "scenes/Brokers";
import Manager from "scenes/Managers/Managers";
import PropertiesManaged from "scenes/PropertiesManaged.jsx/index.jsx";
import ManagerDetails from "scenes/Managers/managerDetails";
import LandlordDetails from "scenes/Landlords/LandlordDetails"; // Ensure correct path
import LandlordExpenses from "scenes/LandlordExpenses";
import Units from "components/Tables/UnitsTable";
import PropertyDetails from "scenes/Properties/propertyDetails";
import BrokerDetails from "scenes/Brokers/BrokerDetails"
import TenantDetails from "scenes/Users/TenantDetails";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";
import { manager_id } from "state";
import BrokerPayments from "scenes/Brokers/BrokerPayments";

function App() {
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const isAuth = Boolean(useSelector((state) => state.token));

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route
              path="/home"
              element={isAuth ? <Dashboard /> : <Navigate to="/" />}
            />
            <Route
              path="/tenants"
              element={isAuth ? <Users /> : <Navigate to="/" />}
            />
            <Route
              path="/saving-tenants"
              element={isAuth ? <Tenants /> : <Navigate to="/" />}
            />
            <Route
              path="/payments"
              element={isAuth ? <Payments /> : <Navigate to="/" />}
            />
            <Route
              path="/properties"
              element={isAuth ? <Properties /> : <Navigate to="/" />}
            />
            <Route
              path="/units"
              element={isAuth ? <Units /> : <Navigate to="/" />}
            />
            <Route
              path="/details"
              element={isAuth ? <PropertyDetails /> : <Navigate to="/" />}
            />
            <Route path="/broker-details" element={<BrokerDetails />} />

            {/* <Route
              path="/broker-details"
              element={isAuth ? <BrokerDetails /> : <Navigate to="/" />}
            /> */}
            <Route
              path="/schedules"
              element={isAuth ? <Tickets /> : <Navigate to="/" />}
            />
            <Route
              path="/profile/:userId"
              element={isAuth ? <ProfilePage /> : <Navigate to="/" />}
            />
            <Route
              path="/landlords"
              element={isAuth ? <Landlords /> : <Navigate to="/" />}
            />
            <Route
              path="/brokers"
              element={isAuth ? <Brokers /> : <Navigate to="/" />}
            />
            <Route
              path="/landlord-details/:id"
              element={isAuth ? <LandlordDetails /> : <Navigate to="/" />}
            />
            <Route
              path="/TenantDetails"
              element={isAuth ? <TenantDetails /> : <Navigate to="/" />}
            />

            <Route
              path="/landlord-expenses"
              element={isAuth ? <LandlordExpenses /> : <Navigate to="/" />}
            />
            <Route
              path="/manager"
              element={isAuth ? <Manager /> : <Navigate to="/" />}
            />
            <Route
              path={`/properties-managed/${manager_id}`}
              element={isAuth ? <PropertiesManaged /> : <Navigate to="/" />}
            />
            <Route
              path="/broker-payments"
              element={isAuth ? <BrokerPayments /> : <Navigate to="/" />}
            />

            <Route path="/manager-details/:id" element={<ManagerDetails />} />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;

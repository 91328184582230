import * as React from "react";
import { useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FEF0DE",
    color: theme.palette.common.black,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function UnitsTable(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // Dummy data for the table
  const rows = [
    {
      id: 1,
      unit_name: "Unit A",
      unit_type: "Type 1",
      unit_size: 50,
      rent_currency: "USD",
      unit_rent: 1200,
      unit_rent_cycle: "Monthly",
    },
    {
      id: 2,
      unit_name: "Unit B",
      unit_type: "Type 2",
      unit_size: 60,
      rent_currency: "USD",
      unit_rent: 1400,
      unit_rent_cycle: "Monthly",
    },
    {
      id: 3,
      unit_name: "Unit C",
      unit_type: "Type 1",
      unit_size: 55,
      rent_currency: "USD",
      unit_rent: 1300,
      unit_rent_cycle: "Monthly",
    },
    {
      id: 4,
      unit_name: "Unit D",
      unit_type: "Type 3",
      unit_size: 70,
      rent_currency: "USD",
      unit_rent: 1500,
      unit_rent_cycle: "Monthly",
    },
    {
      id: 5,
      unit_name: "Unit E",
      unit_type: "Type 2",
      unit_size: 65,
      rent_currency: "USD",
      unit_rent: 1350,
      unit_rent_cycle: "Monthly",
    },
    {
      id: 6,
      unit_name: "Unit F",
      unit_type: "Type 1",
      unit_size: 60,
      rent_currency: "USD",
      unit_rent: 1400,
      unit_rent_cycle: "Monthly",
    },
    {
      id: 7,
      unit_name: "Unit G",
      unit_type: "Type 3",
      unit_size: 75,
      rent_currency: "USD",
      unit_rent: 1600,
      unit_rent_cycle: "Monthly",
    },
    {
      id: 8,
      unit_name: "Unit H",
      unit_type: "Type 2",
      unit_size: 50,
      rent_currency: "USD",
      unit_rent: 1250,
      unit_rent_cycle: "Monthly",
    },
    {
      id: 9,
      unit_name: "Unit I",
      unit_type: "Type 1",
      unit_size: 55,
      rent_currency: "USD",
      unit_rent: 1300,
      unit_rent_cycle: "Monthly",
    },
    {
      id: 10,
      unit_name: "Unit J",
      unit_type: "Type 3",
      unit_size: 70,
      rent_currency: "USD",
      unit_rent: 1550,
      unit_rent_cycle: "Monthly",
    },
    {
      id: 11,
      unit_name: "Unit K",
      unit_type: "Type 2",
      unit_size: 65,
      rent_currency: "USD",
      unit_rent: 1400,
      unit_rent_cycle: "Monthly",
    },
    {
      id: 12,
      unit_name: "Unit L",
      unit_type: "Type 1",
      unit_size: 60,
      rent_currency: "USD",
      unit_rent: 1350,
      unit_rent_cycle: "Monthly",
    },
    {
      id: 13,
      unit_name: "Unit M",
      unit_type: "Type 3",
      unit_size: 75,
      rent_currency: "USD",
      unit_rent: 1650,
      unit_rent_cycle: "Monthly",
    },
    {
      id: 14,
      unit_name: "Unit N",
      unit_type: "Type 2",
      unit_size: 55,
      rent_currency: "USD",
      unit_rent: 1250,
      unit_rent_cycle: "Monthly",
    },
    {
      id: 15,
      unit_name: "Unit O",
      unit_type: "Type 1",
      unit_size: 60,
      rent_currency: "USD",
      unit_rent: 1300,
      unit_rent_cycle: "Monthly",
    },
  ];

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Navbar />
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        <Box
          sx={{
            position: "fixed",
            top: "64px", // Adjust this based on your Navbar height
            left: 0,
            width: "250px", // Adjust as needed
            height: "calc(100vh - 64px)", // Full height minus Navbar height
            backgroundColor: "#f4f4f4", // Adjust the background color as needed
            overflow: "hidden", // Prevent scrolling within the sidebar
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Sidebar />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            marginLeft: "250px", // Match this to Sidebar width
            padding: "2rem",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Rental Units
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Unit Name</StyledTableCell>
                  <StyledTableCell align="right">Unit Type</StyledTableCell>
                  <StyledTableCell align="right">
                    Unit Size (m²)
                  </StyledTableCell>
                  <StyledTableCell align="right">Rent Currency</StyledTableCell>
                  <StyledTableCell align="right">Unit Rent</StyledTableCell>
                  <StyledTableCell align="right">Rent Cycle</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? rows.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : rows
                ).map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell component="th" scope="row">
                      {row.unit_name}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.unit_type}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.unit_size}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.rent_currency}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.unit_rent}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.unit_rent_cycle}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    colSpan={3}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

import * as React from "react";
import {Stack, Select, MenuItem as SelectMenuItem, FormControl, InputLabel} from "@mui/material";

export function BrokerLandlordsForm({newLandlord, handleChange, landlords, isLoadingLandlords}) {
  return (
    <Stack spacing={2}>
        {isLoadingLandlords ? (
            <p>Loading Landlords</p>
        ) : (
            <FormControl fullWidth>
            <InputLabel id="related-landlord-label">
                Select Landlord
            </InputLabel>
            <Select
                labelId="related-landlord-label"
                id="related-landlord"
                name="related_landlord"
                value={newLandlord}
                onChange={handleChange}
                label="Select Landlord"
            >
                {landlords.map((landlord) => (
                <SelectMenuItem key={landlord.id} value={landlord.id}>
                    {`${landlord.related_user.first_name} ${landlord.related_user.last_name}`}
                </SelectMenuItem>
                ))}
            </Select>
            </FormControl>
        )}
    </Stack>
  );
}

import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TableFooter,
  TablePagination,
  Box,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useBrokerPayments } from "services/hooks/rentals";


const PaymentsTable = ({broker}) => {
  const theme = useTheme();
  const { brokerPayments, isLoadingBrokerPayments, errorBrokerPayments } = useBrokerPayments(broker.id);
  // dispatch(setBroker(broker));


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  if (isLoadingBrokerPayments) return <Typography>Loading...</Typography>;
  if (errorBrokerPayments) return <Typography>Error loading payments</Typography>;

  return (
    <>
      {brokerPayments.length > 0 ? (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    background: "linear-gradient(45deg, #FFC0CB 30%, #FFD700 90%)",
                    "& th": {
                      color: "#333333",
                      fontWeight: "bold",
                      fontSize: "16px",
                      backgroundColor: "#FEF0DE",
                    },
                  }}
                >
                  <TableCell>Broker Name</TableCell>
                  <TableCell>Broker Location</TableCell>
                  <TableCell>Landlord Name</TableCell>
                  <TableCell>Date Paid</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? brokerPayments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : brokerPayments
                ).map((brokerPayment) => (
                  <TableRow
                    key={brokerPayment.id}
                    sx={{
                      "&:nth-of-type(even)": { backgroundColor: "#f9f9f9" },
                    }}
                  >
                    <TableCell sx={{ fontSize: "14px" }}>
                      {brokerPayment.related_broker_landlord.related_broker.broker_name}
                    </TableCell>
                    <TableCell sx={{ fontSize: "14px" }}>
                      {brokerPayment.related_broker_landlord.related_broker.broker_location}
                    </TableCell>
                    <TableCell sx={{ fontSize: "14px" }}>
                      {brokerPayment.related_broker_landlord.related_landlord.related_user.first_name}{" "}
                      {brokerPayment.related_broker_landlord.related_landlord.related_user.last_name}
                    </TableCell>
                    <TableCell sx={{ fontSize: "14px" }}>{brokerPayment.date_paid}</TableCell>
                    <TableCell sx={{ fontSize: "14px" }}>{brokerPayment.amount}</TableCell>
                    <TableCell sx={{ fontSize: "14px" }}>{brokerPayment.description}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                    colSpan={6}
                    count={brokerPayments.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Typography>No payments available</Typography>
      )}
    </>
  );
};

export default PaymentsTable;

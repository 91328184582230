import React from "react";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

const AddPaymentForm = ({
  newPayment,
  handleInputChange,
  handleAddPayment,
  handleCloseModal
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <TextField
        label="Date"
        type="date"
        name="date"
        value={newPayment.date}
        onChange={handleInputChange}
        InputLabelProps={{ shrink: true }}
        variant="outlined"
      />
      <TextField
        label="Amount"
        type="number"
        name="amount"
        value={newPayment.amount}
        onChange={handleInputChange}
        variant="outlined"
      />
      <FormControl variant="outlined">
        <InputLabel>Payment Method</InputLabel>
        <Select
          name="method"
          value={newPayment.method}
          onChange={handleInputChange}
          label="Payment Method"
        >
          <MenuItem value="Credit Card">Credit Card</MenuItem>
          <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
          <MenuItem value="Cash">Cash</MenuItem>
        </Select>
      </FormControl>
     
    </Box>
  );
};

export default AddPaymentForm;
